import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Card, CardContent } from "@/components/ui/card";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel"
import { Badge } from  "@/components/ui/badge"
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, MapPin, DollarSign } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { format } from 'date-fns';


import TimeSlotGroup from "./TimeSlotGroup.js"
import ServicesButtonGroup from "./ServicesButtonGroup.js"
import ProfilePicture from "@/components/common/ProfilePicture";

const OpenNowPill = () => {
  return (
    <Badge variant="outline" className="py-1">
      Open Now
    </Badge>
  )
}

const BookNowButton = ({doctorId, productId, date, time}) => {
  const searchParams = new URLSearchParams({
    doctor: doctorId,
    product: productId,
    date: date.toISOString(),
    time: time
  }).toString();

  //Enable the book now button if we've seleted a t
  const isButtonEnabled = (productId && time)

  const DisabledButton = () => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <div className="cursor-not-allowed">
            <Button disabled>Book Now</Button>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Select a time and date to book</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )

  const EnabledButton = () => (
    <Link to={`/billing?${searchParams}`}>
      <Button>Book Now</Button>
    </Link>
  )

  return (
    isButtonEnabled ? <EnabledButton/> : <DisabledButton/>
  );
}

const DoctorDetailsDialog = ({ doctor, isOpen, onClose }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const changeDate = (days) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    setCurrentDate(newDate);
  };

  if (!doctor) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl h-[80vh] flex flex-col">
        <div className="flex-grow overflow-y-auto p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <h2 className="text-2xl font-bold">
                    {doctor.name}
                  </h2>
                  {doctor.isOpen && <OpenNowPill/>}
                </div>
              </div>

              <p className="text-muted-foreground mb-1 font-light flex items-center">
                <MapPin className="mr-1 h-4 w-4" aria-hidden="true" /> 
                <span>{doctor.location}</span>
              </p>
              <p className="text-muted-foreground font-light mb-4">{doctor.specialty}</p>

              {doctor.images && doctor.images.length > 0 ? (
                <Carousel className="w-full max-w-xs">
                  <CarouselContent>
                    {doctor.images.map((img, index) => (
                      <CarouselItem key={index}>
                        <img src={img} alt={`Doctor ${index + 1}`} className="w-full h-64 object-cover" />
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <div className="flex justify-center mt-2">
                    <CarouselPrevious className="relative inset-auto transform-none mx-2" />
                    <CarouselNext className="relative inset-auto transform-none mx-2" />
                  </div>
                </Carousel>
              ) : (
                <ProfilePicture
                  src=""
                  alt={doctor.name}
                  name={doctor.name}
                  className="w-full h-64 object-cover"
                />
              )}
            </div>
            <div>
              <div className="flex items-center justify-between mb-4">
                <Button variant="outline" onClick={() => changeDate(-1)}><ChevronLeft /></Button>
                <span>{format(currentDate, 'PPP')}</span>
                <Button variant="outline" onClick={() => changeDate(1)}><ChevronRight /></Button>
              </div>
              <TimeSlotGroup 
                selectedDate={currentDate}
                selectedTime={selectedTime}
                onTimeSelect={setSelectedTime}
              />
              <ServicesButtonGroup 
                products={doctor.products}
                selectedProduct={selectedProduct}
                onProductSelect={setSelectedProduct}
              />
            </div>
          </div>
          <div className="mt-4">
            <h3 className="text-xl font-semibold mb-2">Description</h3>
            <p className="tracking-tight text-slate-700">{doctor.description}</p>
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center p-4 border-t">
          <div>
            <h3 className="text-xl font-semibold">Price</h3>
            <p className="text-2xl font-bold flex items-center">
              <DollarSign className="h-5 w-5" aria-hidden="true" />
              <span>{doctor.price}</span>
              <span className="text-base font-normal ml-1">per visit</span>
            </p>
          </div>
          <BookNowButton 
            doctorId={doctor.id}
            productId={selectedProduct?.productID}
            date={currentDate}
            time={selectedTime}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DoctorDetailsDialog;
