import React, { useState } from "react"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card"
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "@/components/ui/collapsible"
import { ChevronDown, ChevronUp } from "lucide-react"

import CreditCardInput from "@/components/client/CreditCardInput"

const BillingAddressSection = () => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div>
      <Button
        type="button"
        variant="ghost"
        className="flex w-full justify-between px-2 my-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>Billing Address</span>
        {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
      </Button>
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px]' : 'max-h-0'}`}>
        <div className="space-y-2 py-2">
          <Input placeholder="Street Address" />
          <Input placeholder="City" />
          <Input placeholder="State" />
          <Input placeholder="Country" />
        </div>
      </div>
    </div>
  )
}

const BillingBox = ({ price }) => {
  const [isBillingAddressVisible, setIsBillingAddressVisible] = useState(false)

  return (
    <Card className="w-full max-w-md shadow-md">
      <CardHeader>
        <CardTitle>Billing</CardTitle>
        <CardDescription>Complete your payment to continue.</CardDescription>
      </CardHeader>
      <CardContent>
        <form className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input id="name" placeholder="John Doe" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input id="email" type="email" placeholder="john@example.com" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="card">Card Information</Label>
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <CreditCardInput />
              </div>
              <div>
                <Input maxLength={3} id="cvc" placeholder="CVC" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="expiry">Expiry</Label>
              <div className="grid grid-cols-2 gap-2">
                <Select>
                  <SelectTrigger id="expiry-month">
                    <SelectValue placeholder="Month" />
                  </SelectTrigger>
                  <SelectContent>
                    {[...Array(12)].map((_, i) => (
                      <SelectItem key={i} value={(i + 1).toString().padStart(2, '0')}>
                        {(i + 1).toString().padStart(2, '0')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Select>
                  <SelectTrigger id="expiry-year">
                    <SelectValue placeholder="Year" />
                  </SelectTrigger>
                  <SelectContent>
                    {[...Array(8)].map((_, i) => {
                      const year = new Date().getFullYear() + i;
                      return (
                        <SelectItem key={year} value={year.toString()}>
                          {year}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="zip">Zip Code</Label>
              <Input id="zip" placeholder="90210" />
            </div>
          </div>
          <BillingAddressSection/>
          <Button type="submit" className="w-full">
            Pay ${price}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

export default BillingBox
