import React from "react"
import Appointments from "@/components/hcp/Appointments.js"
import HCPNavBar from "@/components/layout/HCPNavBar"


const AppointmentsPage = () => {
  return(
    <>
      <HCPNavBar />
      <Appointments/>
    </>
  )
}

export default AppointmentsPage
