import React, { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Pencil, Trash2, Plus } from 'lucide-react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"

const NoServices = () => (
  <div className="flex rounded-md justify-center items-center m-8 min-h-[75px] bg-muted/40">
    <p className="text-xl tracking-tight text-slate-600">No services added</p>
  </div>  
)

export default function ServicesPage() {
  const [services, setServices] = useState([
    { id: 1, name: "Web Design", price: 500 },
    { id: 2, name: "SEO Optimization", price: 300 },
    { id: 3, name: "Content Writing", price: 200 },
  ])
  const [newService, setNewService] = useState({ name: '', price: '' })
  const [editingId, setEditingId] = useState(null)
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    const updatedService = { ...newService, price: Number(newService.price) }
    if (editingId !== null) {
      setServices(services.map(service => 
        service.id === editingId ? updatedService : service
      ))
      setEditingId(null)
    } else {
      const id = Math.max(0, ...services.map(s => s.id)) + 1
      setServices([...services, { id, ...updatedService }])
    }
    setNewService({ name: '', price: '' })
    setIsAddDialogOpen(false)
  }


  const startEditing = (service) => {
    setEditingId(service.id)
    setNewService({ name: service.name, price: service.price.toString() })
    setIsAddDialogOpen(true)
  }

  const cancelEditing = () => {
    setEditingId(null)
    setNewService({ name: '', price: '' })
    setIsAddDialogOpen(false)
  }

  const deleteService = (id) => {
    setServices(services.filter(service => service.id !== id))
  }

  return (
    <div className="container mx-auto p-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle>Services</CardTitle>
          <Button variant="outline" size="icon" onClick={() => setIsAddDialogOpen(true)}>
            <Plus className="h-4 w-4" />
          </Button>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Service Name</TableHead>
                <TableHead>Price</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {services.map(service => (
                <TableRow key={service.id}>
                  <TableCell>{service.name}</TableCell>
                  <TableCell>
                    ${service.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </TableCell>
                  <TableCell className="text-right">
                    <Button variant="ghost" size="sm" onClick={() => startEditing(service)}>
                      <Pencil className="h-4 w-4 mr-1" />
                      Edit
                    </Button>
                    <Button variant="ghost" size="sm" onClick={() => deleteService(service.id)}>
                      <Trash2 className="h-4 w-4 mr-1" />
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {services.length === 0 && <NoServices/>}
        </CardContent>
      </Card>

      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{editingId !== null ? 'Edit Service' : 'Add New Service'}</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Service Name</Label>
              <Input
                id="name"
                value={newService.name}
                onChange={e => setNewService({ ...newService, name: e.target.value })}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="price">Price</Label>
              <Input
                id="price"
                type="number"
                value={newService.price}
                onChange={e => setNewService({ ...newService, price: e.target.value })}
                required
                min="0"
                step="0.01"
              />
            </div>
            <DialogFooter>
              <Button type="button" variant="outline" onClick={cancelEditing}>
                Cancel
              </Button>
              <Button type="submit">
                {editingId !== null ? 'Update' : 'Add'} Service
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
