import React from "react"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"


const initials = (name) => {
  const words = name.split(' ');
  const firstInitial = words[0][0].toUpperCase();
  const lastInitial = words[words.length - 1][0].toUpperCase();
  return firstInitial + lastInitial;
}

const ProfilePicture = ({src, alt, name, className}) => {
  return (
    <Avatar className={className ? className : ""}>
      <AvatarImage src={src} alt={name} />
      <AvatarFallback>{initials(name)}</AvatarFallback>
    </Avatar>
  )
}

export default ProfilePicture
