import React from "react"
import { Button } from "@/components/ui/button";

export default function TimeSlotGroup({ selectedDate, selectedTime, onTimeSelect }) {
  const timeSlots = [
    "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30",
    "15:00", "15:30", "16:00", "16:30", "17:00"
  ];

  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Available Time Slots</h3>
      <div className="flex flex-wrap gap-2">
        {timeSlots.map((time) => (
          <Button 
            key={time}
            variant={selectedTime === time ? "default" : "outline"}
            size="sm"
            className="rounded-full mb-2"
            onClick={() => onTimeSelect(time)}
          >
            {time}
          </Button>
        ))}
      </div>
    </div>
  )
}
