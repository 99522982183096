import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { 
  CircleUserRound,
  Menu,
  X
} from 'lucide-react'

import MateoLogo from "@/assets/logo.png"

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <header className="flex items-center justify-between p-4 bg-background border-b">
      <Link to="/" className="flex items-center text-2xl font-bold text-slate-700">
        <img className="w-8 h-7 mr-2 sm:w-12 sm:h-10" src={MateoLogo} alt=""/>
        <span className="hidden sm:inline">Mateos</span>
      </Link>
      
      <div className="flex items-center">
        <Button
          variant="ghost"
          size="icon"
          className="md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </Button>
        
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="secondary" className="hidden md:flex">
              Account
              <CircleUserRound className="ml-2 h-4 w-4"/>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem className="cursor-pointer">Profile</DropdownMenuItem>
            <DropdownMenuItem className="cursor-pointer">Settings</DropdownMenuItem>
            <DropdownMenuItem className="cursor-pointer">Logout</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {isMenuOpen && (
        <div className="absolute top-full left-0 right-0 bg-background border-b md:hidden">
          <nav className="flex flex-col p-4">
            <Link to="/profile" className="py-2">Profile</Link>
            <Link to="/settings" className="py-2">Settings</Link>
            <Link to="/logout" className="py-2">Logout</Link>
          </nav>
        </div>
      )}
    </header>
  )
}

export default NavBar