import React, { useState, useEffect } from "react";
import SearchResults from "./SearchResults";
import LoadingSpinner from "@/components/common/Loader";
import FakeDoctors from "./MOCKDATA.js";

const SearchContainer = ({ initialSearchParams }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const mockFetchResults = async () => {
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    // Here you would typically filter FakeDoctors based on initialSearchParams
    // For now, we'll just return all results
    setSearchResults(FakeDoctors);
    setIsLoading(false);
  };

  useEffect(() => {
    mockFetchResults();
  }, [initialSearchParams]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background">
      <SearchResults results={searchResults} zipcode={initialSearchParams.zipCode} />
    </div>
  );
};

export default SearchContainer;
