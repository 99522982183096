// components/SearchForm.js
import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Slider } from "@/components/ui/slider"


import DropdownMenuCheckboxes from "@/components/common/Dropdown";
import CustomCalendar from "@/components/common/Calendar";
import ZipCode from "@/components/common/Zipcode";

//SVG that we use in the searchbar
function SearchIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  )
}

const SearchBar = () => {
  return (
    <>
      <SearchIcon className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-muted-foreground" />
      <Input
        type="text"
        placeholder="Search for healthcare professionals..."
        className="pl-12 pr-4 py-2 rounded-lg border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
      />
    </>
  )
}

const PriceRange = () => {
  return (
    <>
      <Label htmlFor="price-range" className="block mb-1 text-sm font-medium">
        Price Range
      </Label>
      <div className="flex items-center gap-4">
        <Input
          type="number"
          id="price-range"
          placeholder="Min"
          className="w-full px-4 py-2 rounded-lg border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
        />
        <span>&mdash;</span>
        <Input
          type="number"
          placeholder="Max"
          className="w-full px-4 py-2 rounded-lg border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
        />
      </div>
    </>
  )
}

const SearchForm = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [date, setDate] = useState(null);
  const [zipCode, setZipCode] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [selectedOption, setSelectedOption] = useState('Choose Selection');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch({
      searchTerm,
      date,
      zipCode,
      minPrice,
      maxPrice,
      selectedOption
    })
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl w-full px-4 sm:px-6 lg:px-8">
      <div className="grid gap-6">
        <div className="relative">
          <SearchIcon className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-muted-foreground" />
          <Input
            type="text"
            placeholder="Search for healthcare professionals..."
            className="pl-12 pr-4 py-2 rounded-lg border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <CustomCalendar selectedDate={date} onChange={setDate} />
          </div>
          <div>
            <ZipCode onChange={setZipCode} />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="price-range" className="block mb-1 text-sm font-medium">
              Price Range
            </Label>
            <div className="flex items-center gap-4">
              <Input
                type="number"
                id="price-range"
                placeholder="Min"
                className="w-full px-4 py-2 rounded-lg border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <span>&mdash;</span>
              <Input
                type="number"
                placeholder="Max"
                className="w-full px-4 py-2 rounded-lg border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </div>
          </div>
          <div>
            <DropdownMenuCheckboxes 
              selectedOption={selectedOption}
              onChange={setSelectedOption} 
            />
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            type="submit"
            className="px-6 py-2 rounded-lg bg-primary text-primary-foreground hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            Search
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SearchForm;
