import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Calendar, Check, Clock, X } from "lucide-react"
import { useToast } from "@/components/ui/use-toast"

export default function NewRequestsTable({ requests, onAccept, onDeny }) {
  const { toast } = useToast()

  const handleAccept = (request) => {
    onAccept(request.id)
    toast({
      title: "Request Accepted",
      description: `Appointment for ${request.name} on ${request.date} at ${request.time} has been accepted.`,
    })
  }

  const handleDeny = (request) => {
    onDeny(request.id)
    toast({
      title: "Request Denied",
      description: `Appointment for ${request.name} has been denied.`,
      variant: "destructive",
    })
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Profile</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Time</TableHead>
          <TableHead>Type of Care</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {requests.map((request) => (
          <TableRow key={request.id}>
            <TableCell>
              <Avatar>
                <AvatarImage src={request.avatar} alt={request.name} />
                <AvatarFallback>{request.name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
              </Avatar>
            </TableCell>
            <TableCell className="font-medium">{request.name}</TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <Calendar className="h-4 w-4 text-muted-foreground" />
                {request.date}
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center gap-2">
                <Clock className="h-4 w-4 text-muted-foreground" />
                {request.time}
              </div>
            </TableCell>
            <TableCell>
              <Badge variant="secondary">{request.careType}</Badge>
            </TableCell>
            <TableCell className="text-right">
              <div className="flex justify-end gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleAccept(request)}
                  className="h-8 w-8"
                >
                  <Check className="h-4 w-4" />
                  <span className="sr-only">Accept</span>
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => handleDeny(request)}
                  className="h-8 w-8"
                >
                  <X className="h-4 w-4" />
                  <span className="sr-only">Deny</span>
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}