import React from "react"
import { Button } from "@/components/ui/button";

export default function ServicesButtonGroup({ products, selectedProduct, onProductSelect }) {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Services Available</h3>
      <div className="flex flex-wrap gap-2">
        {products.map((product) => (
          <Button 
            key={product.productID}
            variant={selectedProduct?.productID === product.productID ? "default" : "outline"}
            size="sm"
            className="mb-2"
            onClick={() => onProductSelect(product)}
          >
            {product.name}
          </Button>
        ))}
      </div>
    </div>
  )
}