// import React from 'react'
// import { Card, CardContent } from "@/components/ui/card"
// import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
// import { MapPin, DollarSign } from "lucide-react"
//
// import ProfilePicture from "@/components/common/ProfilePicture"
//
// const SearchResultCard = ({ result, onClick }) => {
//   return (
//     <Card className="cursor-pointer hover:shadow-md transition-shadow" onClick={onClick}>
//       <CardContent className="flex items-center p-4">
//         <ProfilePicture
//           src=""
//           alt=""
//           name={result.name}
//           className="h-24 w-24 mr-4"
//         />
//         <div className="flex-grow">
//           <h2 className="text-2xl font-bold">{result.name}</h2>
//           <p className="text-muted-foreground flex items-center">
//             <MapPin className="mr-1 h-4 w-4" aria-hidden="true" /> 
//             <span>{result.location}</span>
//           </p>
//           <p className="text-muted-foreground">{result.specialty}</p>
//         </div>
//         <div className="text-right">
//           <p className="text-2xl font-bold flex items-center justify-end">
//             <DollarSign className="h-5 w-5" aria-hidden="true" />
//             <span>{result.price}</span>
//           </p>
//           <p className="text-muted-foreground">per visit</p>
//         </div>
//       </CardContent>
//     </Card>
//   )
// }
//
// export default SearchResultCard


import React from 'react'
import { Card, CardContent } from "@/components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { MapPin, DollarSign } from "lucide-react"

import ProfilePicture from "@/components/common/ProfilePicture"

const SearchResultCard = ({ result, onClick }) => {
  return (
    <Card className="cursor-pointer hover:shadow-md transition-shadow" onClick={onClick}>
      <CardContent className="flex items-center p-4">
        <ProfilePicture
          src=""
          alt=""
          name={result.name}
          className="h-24 w-24 mr-4"
        />
        <div className="flex-grow">
          <h2 className="text-2xl font-bold">{result.name}</h2>
          <p className="text-muted-foreground flex items-center">
            <MapPin className="mr-1 h-4 w-4" aria-hidden="true" /> 
            <span>{result.location}</span>
          </p>
          <p className="text-muted-foreground">{result.specialty}</p>
        </div>
        <div className="text-right">
          <p className="text-2xl font-bold flex items-center justify-end">
            <DollarSign className="h-5 w-5" aria-hidden="true" />
            <span>{result.price}</span>
          </p>
          <p className="text-muted-foreground">per visit</p>
        </div>
      </CardContent>
    </Card>
  )
}

export default SearchResultCard
