import { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { ScrollArea } from "@/components/ui/scroll-area"

//Custom
import ProfilePicture from "@/components/common/ProfilePicture"

const AppointmentCard = ({ apptInfo, onClick }) => {

  return (
    <Card className="mb-2 overflow-hidden cursor-pointer hover:bg-accent" onClick={onClick}>
      <CardContent className="p-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <ProfilePicture
              src={apptInfo.avatar}
              alt={apptInfo.name}
              name={apptInfo.name}
            />
            <span className="font-medium">{apptInfo.name}</span>
          </div>
          <span className="text-sm text-muted-foreground">{apptInfo.time}</span>
        </div>
      </CardContent>
    </Card>
  )
}

const ClientDetailsDialog = ({ isOpen, onClose, clientInfo, appointmentInfo }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Client Details</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-4">
            <ProfilePicture
              src={clientInfo.avatar}
              alt={clientInfo.name}
              name={clientInfo.name}
              className="w-20 h-20"
            />
            <h3 className="text-lg font-semibold">{clientInfo.name}</h3>
            <p className="text-sm text-muted-foreground">{clientInfo.email}</p>
            <p className="text-sm text-muted-foreground">{clientInfo.phone}</p>
          </div>
          <div className="space-y-4">
            <h4 className="font-semibold">Appointment Details</h4>
            <p className="text-sm">Date: {appointmentInfo.date}</p>
            <p className="text-sm">Time: {appointmentInfo.time}</p>
            <p className="text-sm">Reason: {appointmentInfo.reason}</p>
          </div>
        </div>
        <Button onClick={onClose} className="mt-4">Close</Button>
      </DialogContent>
    </Dialog>
  )
}

export default function HCPAppointments() {
  const [selectedClient, setSelectedClient] = useState(null)

  // Mock data for appointments
  const appointments = [
    { date: "Monday, June 3", appointments: [
      { name: "John Smith", time: "09:00 AM", avatar: "/placeholder.svg?height=32&width=32", email: "john@example.com", phone: "123-456-7890", reason: "Annual checkup" },
      { name: "Emma Johnson", time: "11:30 AM", avatar: "/placeholder.svg?height=32&width=32", email: "emma@example.com", phone: "098-765-4321", reason: "Follow-up consultation" },
    ]},
    { date: "Tuesday, June 4", appointments: [
      { name: "Michael Brown", time: "10:00 AM", avatar: "/placeholder.svg?height=32&width=32", email: "michael@example.com", phone: "111-222-3333", reason: "New patient consultation" },
      { name: "Sophia Davis", time: "02:30 PM", avatar: "/placeholder.svg?height=32&width=32", email: "sophia@example.com", phone: "444-555-6666", reason: "Prescription renewal" },
      { name: "Oliver Wilson", time: "04:15 PM", avatar: "/placeholder.svg?height=32&width=32", email: "oliver@example.com", phone: "777-888-9999", reason: "Test results review" },
    ]},
  ]

  const handleAppointmentClick = (appointment, date) => {
    setSelectedClient({ ...appointment, date })
  }

  return (
    <Card className="w-full max-w-md">
      <CardHeader>
        <CardTitle>Appointments</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[300px] pr-4">
          {appointments.map((day, dayIndex) => (
            <div key={dayIndex} className="mb-4">
              <h3 className="mb-2 font-semibold text-muted-foreground">{day.date}</h3>
              {day.appointments.map((appointment, appointmentIndex) => (
                <AppointmentCard 
                  key={appointmentIndex} 
                  apptInfo={appointment} 
                  onClick={() => handleAppointmentClick(appointment, day.date)}
                />
              ))}
            </div>
          ))}
        </ScrollArea>
      </CardContent>
      {selectedClient && (
        <ClientDetailsDialog
          isOpen={!!selectedClient}
          onClose={() => setSelectedClient(null)}
          clientInfo={selectedClient}
          appointmentInfo={{
            date: selectedClient.date,
            time: selectedClient.time,
            reason: selectedClient.reason
          }}
        />
      )}
    </Card>
  )
}
