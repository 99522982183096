import { useState } from 'react'
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"

export default function Component() {
  const [cardNumber, setCardNumber] = useState('')

  const formatCreditCardNumber = (value: string) => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    const matches = v.match(/\d{4,16}/g)
    const match = matches && matches[0] || ''
    const parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
      return parts.join(' ')
    } else {
      return value
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatCreditCardNumber(event.target.value)
    setCardNumber(formattedValue)
  }

  return (
    <div className="w-full max-w-sm space-y-2">
      {/* <Label htmlFor="card-number">Credit Card Number</Label> */}
      <Input
        type="text"
        id="card-number"
        name="card-number"
        inputMode="numeric"
        autoComplete="cc-number"
        placeholder="1234 5678 9012 3456"
        value={cardNumber}
        onChange={handleChange}
        maxLength={19}
        aria-describedby="card-number-hint"
      />
      {/* <p id="card-number-hint" className="text-sm text-muted-foreground"> */}
      {/*   Enter your 16-digit credit card number. */}
      {/* </p> */}
    </div>
  )
}
