import React, { useState } from 'react'

//ShadCN
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"

//Lucide & date library
import { BarChart, LineChart, PieChart, ArrowUpDown } from 'lucide-react'
import { format } from 'date-fns';

//Custom
import ProfilePicture from "@/components/common/ProfilePicture"

export default function Patients() {
  const [users, setUsers] = useState([
    { id: 1, name: 'Alice Johnson', firstAppointment: '2022-01-10', lastAppointment: '2023-06-20' },
    { id: 2, name: 'Bob Smith', firstAppointment: '2021-12-05', lastAppointment: '2023-07-02' },
    { id: 3, name: 'Charlie Brown', firstAppointment: '2022-02-18', lastAppointment: '2023-06-30' },
  ])

  const [filter, setFilter] = useState('')
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)

  const filteredUsers = users.filter(user => 
    user.name.toLowerCase().includes(filter.toLowerCase())
  )

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (sortColumn) {
      const dateA = new Date(a[sortColumn]).getTime()
      const dateB = new Date(b[sortColumn]).getTime()
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA
    }
    return 0
  })

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  return (
    <div className="min-h-screen bg-background p-8">
      <div className="mx-auto w-3/4">
        <div className="grid grid-cols-3 gap-4 mb-8">
          <div className="bg-card text-card-foreground rounded-lg shadow-sm p-4 flex flex-col items-center justify-center h-40">
            <BarChart className="h-20 w-20 text-primary mb-2" />
            <span className="text-sm font-medium">Bar Chart</span>
          </div>
          <div className="bg-card text-card-foreground rounded-lg shadow-sm p-4 flex flex-col items-center justify-center h-40">
            <LineChart className="h-20 w-20 text-primary mb-2" />
            <span className="text-sm font-medium">Line Chart</span>
          </div>
          <div className="bg-card text-card-foreground rounded-lg shadow-sm p-4 flex flex-col items-center justify-center h-40">
            <PieChart className="h-20 w-20 text-primary mb-2" />
            <span className="text-sm font-medium">Pie Chart</span>
          </div>
        </div>
        
        <div className="mb-4 flex justify-between items-center">
          <h2 className="text-2xl font-bold">User Appointments</h2>
          <div className="flex gap-2">
            <Input
              placeholder="Filter users..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="max-w-sm"
            />
            <Button onClick={() => setFilter('')}>Clear</Button>
          </div>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Profile</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>
                <Button
                  variant="ghost"
                  onClick={() => handleSort('firstAppointment')}
                  className="flex items-center gap-1"
                >
                  First Appointment
                  <ArrowUpDown className="h-4 w-4" />
                </Button>
              </TableHead>
              <TableHead>
                <Button
                  variant="ghost"
                  onClick={() => handleSort('lastAppointment')}
                  className="flex items-center gap-1"
                >
                  Last Appointment
                  <ArrowUpDown className="h-4 w-4" />
                </Button>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                <ProfilePicture src="path/to/some/picture" name={user.name} />  

                  {/* <img
                    src={`/placeholder.svg?height=40&width=40`}
                    alt={`${user.name}'s profile`}
                    className="rounded-full"
                    width={40}
                    height={40}
                  /> */}
                </TableCell>
                <TableCell className="font-medium">{user.name}</TableCell>
                <TableCell>{format(user.firstAppointment, 'PPP')}</TableCell>
                <TableCell>{format(user.lastAppointment, 'PPP')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
