import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//Common Pages
import Hero from '@/pages/common/Hero'
import SignUp from '@/pages/common/SignUp'
import Home from  '@/pages/common/Home'

//HCP Pages
import HCPDashboard from '@/pages/hcp/Dashboard'
import Transactions from '@/pages/hcp/Transactions'
import Services from '@/pages/hcp/Services'
import Appointments from '@/pages/hcp/Appointments'
import Patients from '@/pages/hcp/Patients'

//Client Pages
import Billing from '@/pages/client/Billing'
import SearchResults from '@/pages/client/SearchResults'
import SearchPage from '@/pages/client/Search'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/signup" element={<SignUp/>} />
        <Route path="/about" element={<Hero/>} />
        <Route path="/dashboard" element={<HCPDashboard/>} />
        <Route path="/transactions" element={<Transactions/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/appointments" element={<Appointments/>} />
        <Route path="/patients" element={<Patients/>} />
        <Route path="/billing" element={<Billing/>} />
        <Route path="/results" element={<SearchResults/>} />
      </Routes>
    </Router>
  );
}

export default App;
