import React, { useState } from 'react';
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"

const ZipCode = ({onChange}) => {
  const [zipCode, setZipCode] = useState('');

  const handleZipCodeChange = (e) => {
    const value = e.target.value;
    // Only allow numbers and limit to 5 characters
    if (/^\d*$/.test(value) && value.length <= 5) {
      setZipCode(value);
      onChange(value)
    }
  };

  return (
    <>
      <Label htmlFor="location" className="block mb-1 text-sm font-medium">
        Zip Code 
      </Label>
      <Input
        type="tel"
        id="location"
        placeholder="Enter a zipcode"
        className="w-full px-4 py-2 rounded-lg border border-input bg-background text-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
        value={zipCode}
        onChange={handleZipCodeChange}
        maxLength={5}
        inputMode="numeric"
        pattern="[0-9]*"
      />
    </>
  )
}

export default ZipCode
