const fakeDoctors = [
  {
    id: 1,
    name: "Dr. Emily Johnson",
    specialty: "Cardiologist",
    location: "New York, NY",
    price: 250,
    isOpen: true,
    description: "Dr. Johnson is a board-certified cardiologist with over 15 years of experience in treating heart conditions. She specializes in preventive cardiology and heart health management.",
    products: [
      { productID: "a1b2c3d4-e5f6-4g7h-8i9j-0k1l2m3n4o5p", name: "Cardiovascular Disease Prevention" },
      { productID: "b2c3d4e5-f6g7-5h8i-9j0k-1l2m3n4o5p6q", name: "Echocardiography" },
      { productID: "c3d4e5f6-g7h8-6i9j-0k1l-2m3n4o5p6q7r", name: "Stress Testing" },
      { productID: "d4e5f6g7-h8i9-7j0k-1l2m-3n4o5p6q7r8s", name: "Holter Monitoring" }
    ],
    images: [
      "https://images.unsplash.com/photo-1550831107-1553da8c8464",
      "https://unsplash.com/photos/ready-to-help-cheerful-pleasant-positive-pediatrician-holding-pen-and-expressing-gladness-while-sitting-at-the-table-FLvJ1TUDuaU",
      "https://plus.unsplash.com/premium_photo-1674760950095-3a25310175a7?q=80&w=2848&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    ]
  },
  {
    id: 2,
    name: "Dr. Michael Chen",
    specialty: "Dermatologist",
    location: "San Francisco, CA",
    price: 200,
    isOpen: false,
    description: "Dr. Chen is a skilled dermatologist known for his expertise in both medical and cosmetic dermatology. He is passionate about helping patients achieve healthy, beautiful skin.",
    products: [
      { productID: "e5f6g7h8-i9j0-8k1l-2m3n-4o5p6q7r8s9t", name: "Acne Treatment" },
      { productID: "f6g7h8i9-j0k1-9l2m-3n4o-5p6q7r8s9t0u", name: "Skin Cancer Screening" },
      { productID: "g7h8i9j0-k1l2-0m3n-4o5p-6q7r8s9t0u1v", name: "Botox Injections" },
      { productID: "h8i9j0k1-l2m3-1n4o-5p6q-7r8s9t0u1v2w", name: "Laser Hair Removal" }
    ],
    images: [
      "https://images.unsplash.com/photo-1511174511562-5f8f6d012e5a",
      "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2",
      "https://images.unsplash.com/photo-1517430816045-df4b7de6d0d5"
    ]
  },
  {
    id: 3,
    name: "Dr. Sarah Patel",
    specialty: "Pediatrician",
    location: "Chicago, IL",
    price: 180,
    isOpen: true,
    description: "Dr. Patel is a compassionate pediatrician dedicated to providing comprehensive care for children from infancy through adolescence. She believes in partnering with families to ensure the best health outcomes for her patients.",
    products: [
      { productID: "i9j0k1l2-m3n4-2o5p-6q7r-8s9t0u1v2w3x", name: "Well-Child Visits" },
      { productID: "j0k1l2m3-n4o5-3p6q-7r8s-9t0u1v2w3x4y", name: "Immunizations" },
      { productID: "k1l2m3n4-o5p6-4q7r-8s9t-0u1v2w3x4y5z", name: "Developmental Screenings" },
      { productID: "l2m3n4o5-p6q7-5r8s-9t0u-1v2w3x4y5z6a", name: "Acute Illness Care" }
    ],
    images: [
      "https://images.unsplash.com/photo-1580281657155-966f56f18d7b",
      "https://images.unsplash.com/photo-1530023367847-ea0e9f1a1658",
      "https://images.unsplash.com/photo-1580281657333-2b5f25fbad30"
    ]
  },
  {
    id: 4,
    name: "Dr. James Wilson",
    specialty: "Orthopedic Surgeon",
    location: "Houston, TX",
    price: 300,
    isOpen: true,
    description: "Dr. Wilson is a highly skilled orthopedic surgeon specializing in sports medicine and joint replacement. He is known for his innovative approaches to minimally invasive surgeries.",
    products: [
      { productID: "m3n4o5p6-q7r8-6s9t-0u1v-2w3x4y5z6a7b", name: "Joint Replacement" },
      { productID: "n4o5p6q7-r8s9-7t0u-1v2w-3x4y5z6a7b8c", name: "Arthroscopic Surgery" },
      { productID: "o5p6q7r8-s9t0-8u1v-2w3x-4y5z6a7b8c9d", name: "Sports Injury Treatment" },
      { productID: "p6q7r8s9-t0u1-9v2w-3x4y-5z6a7b8c9d0e", name: "Fracture Care" }
    ],
    images: [
      "https://images.unsplash.com/photo-1564162037-664f7a17a9f8",
      "https://images.unsplash.com/photo-1598256987451-02efad6f21b1",
      "https://images.unsplash.com/photo-1528459105426-babf16ac70fd"
    ]
  },
  {
    id: 5,
    name: "Dr. Olivia Martinez",
    specialty: "Psychiatrist",
    location: "Miami, FL",
    price: 220,
    isOpen: false,
    description: "Dr. Martinez is a compassionate psychiatrist with expertise in mood disorders, anxiety, and PTSD. She takes a holistic approach to mental health, combining medication management with psychotherapy when appropriate.",
    products: [
      { productID: "q7r8s9t0-u1v2-0w3x-4y5z-6a7b8c9d0e1f", name: "Depression Treatment" },
      { productID: "r8s9t0u1-v2w3-1x4y-5z6a-7b8c9d0e1f2g", name: "Anxiety Management" },
      { productID: "s9t0u1v2-w3x4-2y5z-6a7b-8c9d0e1f2g3h", name: "PTSD Therapy" },
      { productID: "t0u1v2w3-x4y5-3z6a-7b8c-9d0e1f2g3h4i", name: "Medication Management" }
    ],
    images: [
      "https://images.unsplash.com/photo-1504087697493-cf72c32c3a1a",
      "https://images.unsplash.com/photo-1509677446340-d37a9f3c03d4",
      "https://images.unsplash.com/photo-1510022215638-3e9111e27325"
    ]
  }
];

export default fakeDoctors;