import React, { useState } from 'react'
import { BarChart, LineChart, Receipt, ChevronLeft, ChevronRight } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import HCPNavBar from "@/components/layout/HCPNavBar"

// Mock data for transactions
const transactions = [
  { id: 1, clientName: 'Alice Johnson', clientPhoto: 'https://i.pravatar.cc/32?u=alice', amount: 150.00, date: '2023-06-15T10:30:00', service: 'Consultation' },
  { id: 2, clientName: 'Bob Smith', clientPhoto: 'https://i.pravatar.cc/32?u=bob', amount: 200.50, date: '2023-06-14T14:45:00', service: 'Treatment' },
  { id: 3, clientName: 'Carol Williams', clientPhoto: 'https://i.pravatar.cc/32?u=carol', amount: 75.25, date: '2023-06-13T09:15:00', service: 'Follow-up' },
  { id: 4, clientName: 'David Brown', clientPhoto: 'https://i.pravatar.cc/32?u=david', amount: 180.75, date: '2023-06-12T11:00:00', service: 'Consultation' },
  { id: 5, clientName: 'Eva Davis', clientPhoto: 'https://i.pravatar.cc/32?u=eva', amount: 95.00, date: '2023-06-11T16:30:00', service: 'Follow-up' },
  // Add more transactions as needed
]

export default function Component() {
  const [showGraphs, setShowGraphs] = useState(true)

  return (
    <>

    <HCPNavBar/>
    <div className="container mx-auto p-4">
      <div className="flex h-screen">
        {/* Collapsible Graphs Section */}
        <div className={`transition-all duration-300 ease-in-out ${showGraphs ? 'w-2/5' : 'w-0'} overflow-hidden`}>
          <div className="space-y-4 pr-4">
            {/* Histogram */}
            <div className="bg-card p-4  shadow">
              <h3 className="text-lg font-semibold mb-2">Transaction Histogram</h3>
              <div className="h-48 flex items-center justify-center bg-muted rounded">
                <BarChart className="w-12 h-12 text-muted-foreground" />
              </div>
            </div>

            {/* Line Chart 1 */}
            <div className="bg-card p-4  shadow">
              <h3 className="text-lg font-semibold mb-2">Revenue Trend</h3>
              <div className="h-48 flex items-center justify-center bg-muted rounded">
                <LineChart className="w-12 h-12 text-muted-foreground" />
              </div>
            </div>

            {/* Line Chart 2 */}
            <div className="bg-card p-4  shadow">
              <h3 className="text-lg font-semibold mb-2">Client Growth</h3>
              <div className="h-48 flex items-center justify-center bg-muted rounded">
                <LineChart className="w-12 h-12 text-muted-foreground" />
              </div>
            </div>
          </div>
        </div>

        {/* Toggle Button */}
        <Button
          variant="outline"
          size="icon"
          className="h-full rounded-none"
          onClick={() => setShowGraphs(!showGraphs)}
        >
          {showGraphs ? <ChevronLeft className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
        </Button>

        {/* Transactions List */}
        <div className={`transition-all duration-300 ease-in-out ${showGraphs ? 'w-3/5' : 'w-full'} bg-card shadow`}>
          <h2 className="text-2xl font-bold p-4">Transactions</h2>
          <ScrollArea className="h-[calc(100vh-8rem)]">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Client</TableHead>
                  <TableHead>Amount</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Service</TableHead>
                  <TableHead>Receipt</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell className="font-medium">
                      <div className="flex items-center space-x-2">
                        <img
                          src={transaction.clientPhoto}
                          alt={transaction.clientName}
                          width={32}
                          height={32}
                          className="rounded-full"
                        />
                        <span>{transaction.clientName}</span>
                      </div>
                    </TableCell>
                    <TableCell>${transaction.amount.toFixed(2)}</TableCell>
                    <TableCell>{new Date(transaction.date).toLocaleString()}</TableCell>
                    <TableCell>{transaction.service}</TableCell>
                    <TableCell>
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button variant="outline" size="sm">
                            <Receipt className="w-4 h-4 mr-2" />
                            Receipt
                          </Button>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Transaction Receipt</DialogTitle>
                          </DialogHeader>
                          <div className="mt-4">
                            <p><strong>Client:</strong> {transaction.clientName}</p>
                            <p><strong>Amount:</strong> ${transaction.amount.toFixed(2)}</p>
                            <p><strong>Date:</strong> {new Date(transaction.date).toLocaleString()}</p>
                            <p><strong>Service:</strong> {transaction.service}</p>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        </div>
      </div>
    </div>
    </>
  )
}
