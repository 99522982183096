import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { 
  CircleUserRound,
  Menu,
  Calendar,
  Users,
  DollarSign,
  Briefcase
} from 'lucide-react'

import MateoLogo from "@/assets/logo.png"

const NavLink = ({name, to, icon}) => {
  return (
    <Button
      asChild
      variant="ghost"
      className="text-lg font-light tracking-tight text-slate-600 w-full md:w-auto justify-start"
    >
      <Link to={to} className="flex items-center">
        {icon}
        <span className="ml-2">{name}</span>
      </Link>
    </Button>
  )
}

const HCPNavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <header className="flex flex-wrap items-center justify-between p-4 bg-background border-b">
      <div className="flex justify-between items-center w-full md:w-auto">
        <Link to="/dashboard" className="flex items-center text-2xl font-bold text-slate-700">
          <img className="w-8 h-7 mr-2 sm:w-12 sm:h-10" src={MateoLogo} alt=""/>
          <span className="hidden sm:inline">Mateos</span>
        </Link>

        <Button
          variant="ghost"
          size="icon"
          className="md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Menu className="h-6 w-6" />
        </Button>
      </div>

      <nav className={`${isMenuOpen ? 'flex' : 'hidden'} md:flex flex-col md:flex-row w-full md:w-auto mt-4 md:mt-0 items-start md:items-center gap-2 md:gap-4`}>
        <NavLink to="/appointments" name="Appointments" icon={<Calendar className="h-5 w-5" />} />
        <NavLink to="/patients" name="Patients" icon={<Users className="h-5 w-5" />} />
        <NavLink to="/transactions" name="Transactions" icon={<DollarSign className="h-5 w-5" />} />
        <NavLink to="/services" name="My Services" icon={<Briefcase className="h-5 w-5" />} />
      </nav>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" className="mt-4 md:mt-0 w-full md:w-auto">
            Account
            <CircleUserRound className="ml-2 h-4 w-4"/>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem className="cursor-pointer">Profile</DropdownMenuItem>
          <DropdownMenuItem className="cursor-pointer">Settings</DropdownMenuItem>
          <DropdownMenuItem className="cursor-pointer">Logout</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  )
}

export default HCPNavBar