import { React, useState } from "react"

import { format }   from 'date-fns';
import { Label }    from "@/components/ui/label"
import { Calendar } from "@/components/ui/calendar"
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover"
import { Button }   from "@/components/ui/button"

function CalendarIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8 2v4" />
      <path d="M16 2v4" />
      <rect width="18" height="18" x="3" y="4" rx="2" />
      <path d="M3 10h18" />
    </svg>
  )
}

const CustomCalendar = ({selectedDate, onChange}) => {

  //Function that takes in a date and returns true if the date is less than today
  const minDateToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set today's time to 00:00:00

    return date < today;
  };
  // const [selectedDate, setSelectedDate] = useState(null)

  return (
    <>
      <Label htmlFor="date" className="block mb-1 text-sm font-medium">
        Date
      </Label>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="w-full flex items-center justify-between px-4 py-2 rounded-lg">
            <span>{selectedDate ? format(selectedDate, 'PPP') : 'Select a date'}</span>
            <CalendarIcon className="w-5 h-5 text-muted-foreground" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <Calendar 
            mode="single"
            disabled={minDateToday}
            selected={selectedDate}
            onSelect={onChange}
            required
          />
        </PopoverContent>
      </Popover>
    </>
  )
}

export default CustomCalendar
