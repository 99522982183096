import React from 'react';
import { Loader2 } from "lucide-react"

const LoadingSpinner = ({ size = "default", variant = "outline" }) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center gap-4">
        <Loader2 className="text-slate-700 mr-2 h-24 w-24 animate-spin" />
        <p className="text-stone-500 text-lg tracking-light font-light">Loading...</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
