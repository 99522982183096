// import * as React from "react"
import { React, useState } from "react"
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu"
import { ChevronDown, ChevronUp  } from 'lucide-react';

import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

// type Checked = DropdownMenuCheckboxItemProps["checked"]

export default function DropdownMenuCheckboxes({selectedOption, onChange}) {

  // const [selection, setSelection] = useState('Choose Selection')
  const [showStatusBar, setShowStatusBar] = useState(true)
  const [showActivityBar, setShowActivityBar] = useState(false)
  const [showPanel, setShowPanel] = useState(false)

  const DropdownOptions = ({ options }) => {
    return (
      <>
        {options.map((opt, i) => (
          <DropdownMenuCheckboxItem
            className="cursor-pointer"
            key={i}
            checked={selectedOption === opt} // Only the selected option will be checked
            value={opt}
            onCheckedChange={() => onChange(opt)} // Set the selection to the chosen option
          >
            {opt}
          </DropdownMenuCheckboxItem>
        ))}
      </>
    );
  };
    
  // const DropdownOptions = ({ options }) => {
  //   return (
  //     <>
  //       {options.map((opt, i) => (
  //         <DropdownMenuCheckboxItem
  //           className="cursor-pointer"
  //           key={i}
  //           checked={false}//{showStatusBar}
  //           value={opt}
  //           onCheckedChange={setShowStatusBar}
  //         >
  //           {opt}
  //         </DropdownMenuCheckboxItem>
  //       ))}
  //     </>
  //   );
  // };

  const healthCareOptions = [
    "Physician (Doctor) MD, DO",
    "Nurse Practitioner NP, CRNA",
    "Registered Nurse RN",
    "Psychologist/Therapist",
    "Physical Therapist PT",
    "Other Healthcare Provider"
  ]


  return (
    <>
      <Label className="block mb-1 text-sm font-medium">Specialist Type</Label>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="w-full" variant="outline">
            {selectedOption}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Specialist Type</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownOptions options={healthCareOptions}/>
          {/* <DropdownMenuCheckboxItem */}
          {/*   checked={showStatusBar} */}
          {/*   onCheckedChange={setShowStatusBar} */}
          {/* > */}
          {/*   Status Bar */}
          {/* </DropdownMenuCheckboxItem> */}
          {/* <DropdownMenuCheckboxItem */}
          {/*   checked={showActivityBar} */}
          {/*   onCheckedChange={setShowActivityBar} */}
          {/*   disabled */}
          {/* > */}
          {/*   Activity Bar */}
          {/* </DropdownMenuCheckboxItem> */}
          {/* <DropdownMenuCheckboxItem */}
          {/*   checked={showPanel} */}
          {/*   onCheckedChange={setShowPanel} */}
          {/* > */}
          {/*   Panel */}
          {/* </DropdownMenuCheckboxItem> */}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
