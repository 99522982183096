import { ScrollArea } from "@/components/ui/scroll-area"

export default function Component() {
  const timeSlots = [
    "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30",
    "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30",
    "17:00", "17:30", "18:00", "18:30", "19:00",
  ]
  const meetings = [
    { startTime: "10:00", endTime: "11:00", title: "Meeting", color: "bg-teal-300" },
    { startTime: "12:00", endTime: "13:00", title: "Lunch Break", color: "bg-blue-300" },
    { startTime: "14:30", endTime: "16:00", title: "Project Review", color: "bg-orange-300" },
    { startTime: "17:00", endTime: "17:30", title: "Quick Catch-up", color: "bg-green-300" },
  ]

  const getPositionAndHeight = (startTime, endTime) => {
    const startIndex = timeSlots.indexOf(startTime)
    const endIndex = timeSlots.indexOf(endTime)
    const top = startIndex * 48 // Each slot is 48px high
    const height = (endIndex - startIndex) * 48
    return { top, height }
  }

  return (
    <div className="max-w-4xl mx-auto border rounded-lg overflow-hidden">
      <ScrollArea className="h-[600px]">
        <div className="relative">
          {timeSlots.map((time, index) => (
            <div key={index} className="flex h-12 border-b">
              <div className="w-24 flex-shrink-0 text-gray-500 pr-4 text-right">{time}</div>
              <div className="flex-grow"></div>
            </div>
          ))}
          {meetings.map((meeting, index) => {
            const { top, height } = getPositionAndHeight(meeting.startTime, meeting.endTime)
            return (
              <div
                key={index}
                className={`absolute left-24 right-4 p-2 rounded ${meeting.color}`}
                style={{ top: `${top}px`, height: `${height}px` }}
              >
                <span className="font-bold">{meeting.title}</span>
              </div>
            )
          })}
        </div>
      </ScrollArea>
    </div>
  )
}
