import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SearchContainer from "@/components/client/SearchContainer";
import NavBar from "@/components/layout/NavBar";
import CardSignIn from "@/components/auth/SignInCard";

const SearchResults = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [searchCriteria, setSearchCriteria] = useState({
    searchTerm: searchParams.get('searchTerm') || '',
    date: searchParams.get('date') ? new Date(searchParams.get('date')) : null,
    zipCode: searchParams.get('zipCode') || '',
    minPrice: searchParams.get('minPrice') || '',
    maxPrice: searchParams.get('maxPrice') || '',
    selectedOption: searchParams.get('selectedOption') || 'Choose Selection'
  });

  return (
    <>
      <NavBar />
      {/* <CardSignIn /> */}
      <SearchContainer initialSearchParams={searchCriteria} />
    </>
  );
};

export default SearchResults;
