import { Card, CardContent } from "@/components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Stethoscope, MapPin, User } from "lucide-react"
import ProfilePicture from "@/components/common/ProfilePicture"

export default function HCPProfile() {
  return (
    <Card className="w-full max-w-sm mx-auto">
      <CardContent className="flex flex-col items-center pt-6 pb-4">
        <ProfilePicture 
          src="/placeholder.svg?height=128&width=128"
          alt="Profile"
          name="Jane Doe"
          className="w-32 h-32"
        />
        <h2 className="mt-4 text-2xl font-bold">Dr. Jane Doe</h2>
        <div className="w-full mt-4 space-y-3">
          <div className="flex items-center">
            <Stethoscope className="w-5 h-5 mr-2 text-gray-500" />
            <span>Cardiologist</span>
          </div>
          <div className="flex items-center">
            <MapPin className="w-5 h-5 mr-2 text-gray-500" />
            <span>123 Medical Center, New York, NY</span>
          </div>
          <div className="flex items-start">
            <User className="w-5 h-5 mr-2 mt-1 text-gray-500 flex-shrink-0" />
            <p className="text-sm">
              Dedicated cardiologist with over 10 years of experience in treating heart conditions. Passionate about preventive care and patient education.
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
