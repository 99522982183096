import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button"

//Custom
import Schedule from "@/components/common/Schedule"
import HCPNavBar from "@/components/layout/HCPNavBar"
import HCPProfile from "@/components/hcp/Profile"
import HCPAppointments from "@/components/hcp/AppointmentsSidebar"


const HCPDashboard = () => {
  return (
    <>
      <HCPNavBar />
      <div className="grid grid-cols-1 md:grid-cols-4 min-h-screen">
        <div className="hidden md:flex md:col-span-1 justify-center items-start px-8 py-12">
          <div className="w-full max-w-sm">
            <HCPProfile />
          </div>
        </div>
        <div className="col-span-1 md:col-span-2">
          <Schedule />
        </div>
        <div className="hidden md:flex md:col-span-1 justify-center items-start px-8 py-12">
          <div className="w-full max-w-sm">
            <HCPAppointments />
          </div>
        </div>
      </div>
    </>
  )
}

export default HCPDashboard
