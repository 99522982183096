import React from "react"
import Services from "@/components/hcp/Services"
import HCPNavbar from "@/components/layout/HCPNavBar"

const ServicePage = () => {
  return (
    <>
      <HCPNavbar/>
      <Services/>
    </>
  )
}
export default  ServicePage

