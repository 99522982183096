import React, { useState, useEffect } from "react"
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"
import SearchResultCard from "./SearchResultCard"
import SearchForm from "./SearchForm.js"
import DoctorDetailsDialog from "./DoctorDetailsDialog.js"

const GOOGLE_MAPS_API_KEY = "AIzaSyCLw3IT-X6YJci-Uk4BqMFc4Pa4i0H8SMg"

const mapContainerStyle = {
  width: "100%",
  height: "100%",
}

const SearchResults = ({ results, zipcode }) => {
  const [center, setCenter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedDoctor, setSelectedDoctor] = useState(null)

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["places"]
  })

  useEffect(() => {
    if (isLoaded && zipcode) {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address: zipcode.toString() }, (results, status) => {
        if (status === "OK") {
          setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          })
        } else {
          // Handle geocoding error
        }
        setLoading(false)
      })
    }
  }, [isLoaded, zipcode])

  if (loadError) {
    return <div>Error loading maps</div>
  }

  return (
    <>
      <div className="flex w-full h-screen">
        <div className="w-full md:w-3/5 p-8 overflow-y-auto">
          <div className="flex flex-col gap-8">
            {results.map((result, index) => (
              <SearchResultCard
                key={result.id || index}
                result={result}
                onClick={() => setSelectedDoctor(result)}
              />
            ))}
          </div>
        </div>
        <div className="hidden md:grid md:w-2/5 md:grid-rows-2">
          <div className="w-full h-full">
            {center && (
              <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={10}>
                {results.map((result, index) => (
                  <Marker
                    key={result.id || index}
                    position={{ lat: result.latitude, lng: result.longitude }}
                  />
                ))}
              </GoogleMap>
            )}
          </div>
          <div className="w-full p-4 flex items-center justify-center">
            <SearchForm />
          </div>
        </div>
      </div>
      <DoctorDetailsDialog 
        doctor={selectedDoctor}
        isOpen={!!selectedDoctor}
        onClose={() => setSelectedDoctor(null)}
      />
    </>
  )
}

export default SearchResults