import React from "react"

import BillingBox from "@/components/client/BillingBox"
import NavBar from "@/components/layout/NavBar"
import OrderDetails from "@/components/client/OrderDetails"

const mockOrder = {
  id: "ORD-12345",
  date: "2023-09-15",
  time: "9:30 AM",
  item: { 
    name: "Consultation Service", 
    price: 150.00 
  },
  doctor: 'Dr. William Johnson',
  serviceFee: 10.00,
  tax: 12.80,
  total: 172.80,
  appointment: {
    address: "123 Main St",
    cityStateZip: "Anytown, ST 12345"
  },
  customer: {
    name: "John Doe",
    email: "john@example.com",
    phone: "+1 (555) 123-4567"
  },
  payment: {
    cardType: "Visa",
    cardNumber: "**** **** **** 1234"
  },
  lastUpdated: "2023-09-15T14:30:00Z"
}

export default function Billing() {
  return (
    <>
      <NavBar />
      <div className="flex justify-center">
        <div className="w-full max-w-6xl p-4 md:p-8">
          <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-8">
            <div className="w-full md:w-1/2">
              <OrderDetails order={mockOrder} />
            </div>
            <div className="w-full md:w-1/2">

              <BillingBox price={mockOrder.total.toFixed(2)} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
